<template>
  <div class="pa-3">
    <v-data-table
      :headers="Headers"
      :items="mps"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>MPs INFORMATION</b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>
      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'MpsInformation', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <!-- <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon> -->
      </template>
       
    </v-data-table>

    <vue-excel-xlsx
      class="mt-2 text-center"
      :data="mps"
      :columns="exceldatas"
      :filename="'Mps Data'"
      :sheetname="'Mps Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mpsdata } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "MpsInformationTable",
  mixins: [snackMixin],
  data() {
    return {
      exceldatas: mpsdata,
      search: "",
      Headers: [
        { text: "Ref No", value: "CDPRef" },
        { text: "Approved MPs/GMPs", value: "MPsApproved" },
        { text: "Agency Contribution", value: "AgencyContribution" },
        { text: "Community Contribution", value: "CommunityContribution" },
        { text: "Total Cost of MP/GMP", value: "MPsTotalCost" },
        { text: "Sector of the MP", value: "Sector" },
        { text: "Cordinates (Latitude)", value: "CordinatesLat" },
        { text: "Cordinates (Longitude)", value: "CordinatesLong" },
        { text: "First Tranches", value: "FirstTranche" },
        { text: "Date Release First Tranche", value: "FirstTrancheDate" },
        { text: "Second Tranche", value: "SecondTranche" },
        { text: "Date Release of Second Tranche", value: "SecondTrancheDate" },
        { text: "Third Tranche", value: "ThirdTranche" },
        { text: "Date Release Third Tranche", value: "ThirdTrancheDate" },
        { text: "Fund Not Yet Release", value: "FundNotYetRelease" },
        { text: "Commence  Date", value: "CommenceDate" },
        { text: "Status", value: "Status" },
        { text: "Comment", value: "mpsComment" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
      mps: [],
    };
  },

  created() {
    apiClient
      .get("/mps_info?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.mps = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    /* del(item) {
      const ok = confirm(`Are sure you want to delete ${item.MPsRefNo}?`);
      if (ok) {
        this.displayMsg(`Deleting ${item.MPsRefNo}, please wait...`);
        apiClient
          .delete(`/mps_info?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.MPsRefNo} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.mps = this.mps.filter((pt) => pt.id !== item.id);
              this.displayMsg(`Successfully deleted ${item.MPsRefNo} `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    }, */
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
