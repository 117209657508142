export const administrativeData = [
  { label: "Meeting Types", field: "MeetingType" },
  { label: "Meeting", field: "Meeting" },
  { label: "Date of Meeting", field: "Date" },
  { label: "No of Attendee", field: "Attendee" },
  { label: "IVA Assessment Date", field: "IVA" },
  { label: "Sensitization Visit to Stakeholders", field: "Sensitization" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];

export const ExpressData = [
  { label: "Communtiy Name", field: "CommunityName" },
  { label: "Date Submitted", field: "DateSubmitted" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];


export const financeData = [
  { label: "Withdrawal application submitted", field: "WithdrawalApplication" },
  { label: "Replenishment Application submitted", field: "ReplenishmentApplication" },
  { label: "Replenishment received", field: "ReplenishmentReceived" },
  { label: "Amount replenished", field: "AmountReplenished" },
  { label: "Government Contribution", field: "GovtContribution" },
  { label: "Interest", field: "Interest" },
  { label: "Total Income", field: "TotalIncome" },
  { label: "Goods", field: "Goods" },
  { label: "Non-Consulting", field: "NonConsulting" },
  { label: "Consultancy", field: "Consultancy" },
  { label: "Operating Cost", field: "OperatingCost" },
  { label: "Training", field: "Training" },
  { label: "Others", field: "Others" },
  { label: "Grants - CDP", field: "GrantsCdp" },
  { label: "Grants - GDP", field: "GrantsGdp" },
  { label: "Total Expenditure", field: "TotalExpenditure" },
  { label: "Balance in IDA Replenished A/C ", field: "BalanceIda" },
  { label: "Balance in Govt. Contribution A/C", field: "BalanceGovt" },
  { label: "Balance in Interest Account", field: "BalanceInterest" },
  { label: "Date", field: "InputDate" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Modified On", field: "modified_on" },
];
export const cdpData = [
  { label: "Ref No", field: "CDPRef" },
  { label: "Community/Group Name", field: "GroupName" },
  { label: "Type of Project", field: "projects" },
  { label: "Environmental Screening Conducted", field: "EnvironmentScreen" },
  { label: "Social Screening Conducted", field: "SocialScreen" },
  { label: "Environmental Audit Conducted", field: "EnvironmentAudit" },
  { label: "Social Audit Conducted", field: "SocialAudit" },
  { label: "Community Conflict", field: "CommConflict" },
  { label: "VLD Protocol Developed", field: "VLDProtocol" },
  { label: "Date of Sentization", field: "sentization" },
  { label: "Need Assessment Date", field: "AssessmentDate" },
  { label: "CPMC/GPMC Election Date", field: "ElectionDate" },
  { label: "Desk Appraisal Date", field: "DeskDate" },
  { label: "LGRC Review Date", field: "ReviewDate" },
  { label: "CDP Submission Date", field: "SubmissionDate" },
  { label: "Field Appraisal Date", field: "FieldDate" },
  { label: "Date of Approval", field: "ApprovalDate" },
  { label: "CPMC/GPMC Training Date", field: "TrainingDate" },
  { label: "Project Launch Date", field: "LaunchDate" },
  { label: "Total cost of the CDP", field: "CDPTotalCost" },
  { label: "Status", field: "CDPStatus" },
  { label: "CDP Completed Date", field: "CompletedDate" },
  { label: "Approved MPs/GMPs 1", field: "ApprovedMPs1" },
  { label: "Approved MPs/GMPs 2", field: "ApprovedMPs2" },
  { label: "Approved MPs/GMPs 3", field: "ApprovedMPs3" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];
export const communityData = [
  { label: "Ref No", field: "CDPRef" },
  { label: "Community/Group Name", field: "CommunityName" },
  { label: "Senatorial District", field: "SenatorialDist" },
  { label: "LGA", field: "LGA" },
  { label: "Ward", field: "Ward" },
  { label: "Population", field: "Population" },
  { label: "No of Male", field: "CommunityMaleNo" },
  { label: "No of Female", field: "CommunityFemaleNo" },
  { label: "Social Group Elderly", field: "SocialGroupElder" },
  { label: "Social Group Men", field: "SocialGroupMen" },
  { label: "Social Group Women", field: "SocialGroupWomen" },
  { label: "Social Group Youth", field: "SocialGroupYouth" },
  { label: "Social Group Vulnerables", field: "SocialGroupVulnerable" },
  { label: "Total No of Beneficiaries", field: "noOfBeneficiaries" },
  { label: "Name of Beneficiaries", field: "Beneficiaries" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];
export const cmpcData = [
  { label: "Ref No", field: "CDPRef" },
  { label: "Types of Committee", field: "Committee" },
  { label: "Election Date", field: "ElectionDate" },
  { label: "Training Date", field: "TrainingDate" },
  { label: "No of CPMC/GrPMC", field: "NumberOfCpmc" },
  { label: "No of Male", field: "CPMCMaleNo" },
  { label: "Percentage of Male", field: "PercentOfMale" },
  { label: "No of Female", field: "CPMCFemaleNo" },
  { label: "Percentage of Female", field: "PercentOfFemale" },
  { label: "Vulnerability", field: "Vulnerability" },
  { label: "Registration Date", field: "RegDate" },
  { label: "No of Trained CPMC/GrPMC", field: "CPMCTrained" },
  { label: "List of CPMC/GPMC", field: "CPMCLists" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];
export const mpsdata = [
  { label: "Ref No", field: "CDPRef" },
  { label: "Approved MPs/GMPs", field: "MPsApproved" },
  { label: "Agency Contribution", field: "AgencyContribution" },
  { label: "Community Contribution", field: "CommunityContribution" },
  { label: "Total Cost of MP/GMP", field: "MPsTotalCost" },
  { label: "Sector of the MP", field: "Sector" },
  { label: "Cordinates (Latitude)", field: "CordinatesLat" },
  { label: "Cordinates (Longitude)", field: "CordinatesLong" },
  { label: "First Tranches", field: "FirstTranche" },
  { label: "Date Release First Tranche", field: "FirstTrancheDate" },
  { label: "Second Tranche", field: "SecondTranche" },
  { label: "Date Release of Second Tranche", field: "SecondTrancheDate" },
  { label: "Third Tranche", field: "ThirdTranche" },
  { label: "Date Release Third Tranche", field: "ThirdTrancheDate" },
  { label: "Fund Not Yet Release", field: "FundNotYetRelase" },
  { label: "Commence  Date", field: "CommenceDate" },
  { label: "Status", field: "Status" },
  { label: "Comment", field: "mpsComment" },
  { label: "Created By", field: "created_by" },
  { label: "Modified By", field: "modified_by" },
  { label: "Date Created", field: "created_on" },
  { label: "Date Modified", field: "modified_on" },
];